import { gql } from "@apollo/client";

export const VACANCY_LIST_BY_CATEGORY = gql`
  query vacancyListByCategory($categoryId: Int!, $pagination: PaginationType) {
    vacancyListByCategory(categoryId: $categoryId, pagination: $pagination) {
      ... on PrivateVacancyListSuccess {
        data {
          additionalAvito {
            businessArea
            experience
            name
          }
          inWork
          inBlocked
          additionalHh {
            acceptHandicapped
            acceptKids
            acceptTemporary
            area
            driverLicense
            name
            experience
            languages
            professionalRoles
          }
          address {
            block
            blockType
            city
            house
            cityType
            houseType
            lat
            region
            lng
            regionType
            settlement
            street
            streetType
            value
          }
          age {
            from
            to
          }
          callCenterInfo {
            addresses
            description
            driverLicense
            experience
            farePayment
            goodsCategory
            mobilePayment
            personalCar
            presenceOfPda
            project
            vaccination
          }
          departmentId
          candidateCount
          citizenship {
            id
            key
            name
          }
          city
          claims
          closedAt
          contacts {
            email
            fullName
            phone
          }
          createdAt
          counterparty
          deletedAt
          description
          employer {
            id
            key
            logo
            name
          }
          employment {
            id
            key
            name
          }
          extraAvito {
            publicationFinishTime
            remoteId
            remoteUrl
          }
          extraHh {
            publicationFinishTime
            remoteId
            remoteUrl
          }
          id
          kladrId
          manager {
            city
            companyId
            firstName
            email
            departmentId
            id
            phone
            role
            secondName
          }
          location {
            lat
            lng
          }
          medicalBook
          metro {
            line
            name
            distance
          }
          name
          peopleCount
          peopleCountOpen
          peopleCountClose
          phonePresence
          position {
            categoryId
            id
            name
            key
          }
          updatedAt
          tradePoint
          terms
          tag {
            color
            colorBg
            name
            key
          }
          schedule
          shiftEnd
          shiftStart
          shortDescription
          showContacts
          status
          subdivision
          salary {
            currency
            from
            gross
            to
          }
          priority
          positionId
          publishedAt
          reason
          refusal {
            id
            key
            name
            type
          }
          refusalText
          region
          relevance
          replyCount
          replyCountNew
          responsiblePerson
          route
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;
