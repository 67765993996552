import React from "react";

type Props = React.SVGAttributes<SVGElement> & {
  width?: number;
  height?: number;
  color?: string;
  type: "small" | "large";
};

export const LogoIcon: React.FC<Props> = ({ type }) => {
  return (
    <>
      {type === "small" ? (
        <svg
          width="24"
          height="28"
          viewBox="0 0 24 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.3226 8.75235C23.334 9.17968 23.1806 9.55122 22.9008 9.88558C21.404 11.6756 19.5544 13.0651 17.4219 14.1336C16.9632 14.3633 16.4939 14.5725 16.0158 14.7634C15.9421 14.793 15.9109 14.8322 15.8978 14.9052C15.7448 15.7589 15.572 16.6092 15.4388 17.4659C15.4183 17.5993 15.3991 17.7331 15.374 17.8656C15.3587 17.9456 15.3786 17.9759 15.4728 17.9911C16.9044 18.2189 18.2733 18.6336 19.581 19.2236C19.8987 19.3671 20.2122 19.5191 20.5192 19.6812C21.5205 20.2098 21.9098 21.2264 21.4716 22.2242C21.1136 23.04 20.4682 23.538 19.5155 23.64C19.0522 23.6894 18.624 23.5663 18.2213 23.3558C17.1052 22.772 15.9208 22.3653 14.6582 22.1731C13.0962 21.935 11.5566 22.0346 10.0484 22.4878C8.48577 22.9573 7.09629 23.7236 5.82725 24.6905C5.15075 25.2058 4.55749 25.7992 3.96954 26.4001C3.53035 26.8494 2.97853 27.1177 2.31443 27.1323C1.5926 27.1479 0.998629 26.8826 0.556251 26.3537C0.0554315 25.7547 -0.143267 25.084 0.109976 24.3333C0.226857 23.9866 0.489663 23.7276 0.729447 23.4574C2.13769 21.8702 3.83282 20.6132 5.76349 19.6294C5.8485 19.5862 5.90977 19.5317 5.95723 19.4521C6.593 18.3823 7.00669 17.2371 7.27941 16.0434C7.30066 15.9498 7.30739 15.8518 7.3421 15.7632C7.39381 15.6314 7.3251 15.6042 7.20857 15.5812C5.66645 15.2761 4.21641 14.7419 2.84146 14.0247C2.23155 13.7066 1.82353 13.2444 1.71586 12.5837C1.51822 11.3701 2.48444 10.2007 3.7248 10.0559C4.20932 9.99946 4.65772 10.0977 5.07814 10.3172C6.18993 10.8979 7.36512 11.312 8.62284 11.5112C9.09036 11.5852 9.56143 11.6224 10.0357 11.638C10.714 11.6603 11.3904 11.6789 12.0655 11.5852C12.7101 11.4959 13.3172 11.304 13.8977 11.0298C14.4984 10.7462 15.119 10.5008 15.6991 10.1781C16.9919 9.45892 18.1451 8.58335 19.1291 7.51521C19.4932 7.12009 19.9079 6.79105 20.4534 6.63931C21.4557 6.3604 22.491 6.77312 23.0106 7.65566C23.2114 7.99665 23.3322 8.35823 23.3226 8.75269V8.75235Z"
            fill="white"
          />
          <path
            d="M10.8643 8.94426C13.499 8.94426 15.6348 6.94202 15.6348 4.47213C15.6348 2.00224 13.499 0 10.8643 0C8.22959 0 6.09375 2.00224 6.09375 4.47213C6.09375 6.94202 8.22959 8.94426 10.8643 8.94426Z"
            fill="#E4244B"
          />
        </svg>
      ) : (
        <svg
          width="158"
          height="34"
          viewBox="0 0 158 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_6434_4247)">
            <path
              d="M23.7855 13.1352C23.7968 13.5625 23.6435 13.934 23.3637 14.2684C21.8669 16.0584 20.0173 17.4479 17.8848 18.5164C17.4261 18.7462 16.9568 18.9553 16.4787 19.1463C16.405 19.1758 16.3738 19.215 16.3607 19.288C16.2077 20.1417 16.0349 20.992 15.9017 21.8487C15.8811 21.9821 15.862 22.1159 15.8369 22.2484C15.8216 22.3284 15.8415 22.3587 15.9357 22.3739C17.3673 22.6017 18.7362 23.0164 20.0439 23.6064C20.3616 23.7499 20.675 23.9019 20.9821 24.064C21.9834 24.5926 22.3727 25.6093 21.9345 26.607C21.5765 27.4228 20.9311 27.9209 19.9784 28.0228C19.5151 28.0723 19.0869 27.9491 18.6842 27.7386C17.5681 27.1549 16.3837 26.7481 15.1211 26.5559C13.5591 26.3178 12.0195 26.4174 10.5113 26.8706C8.94866 27.3401 7.55919 28.1065 6.29014 29.0733C5.61364 29.5886 5.02038 30.182 4.43243 30.783C3.99324 31.2322 3.44142 31.5005 2.77732 31.5151C2.05549 31.5307 1.46152 31.2654 1.01914 30.7365C0.518322 30.1375 0.319624 29.4668 0.572867 28.7161C0.689748 28.3694 0.952554 28.1104 1.19234 27.8402C2.60058 26.2531 4.29572 24.996 6.22638 24.0122C6.31139 23.969 6.37266 23.9146 6.42012 23.8349C7.05589 22.7651 7.46958 21.6199 7.7423 20.4262C7.76355 20.3326 7.77028 20.2347 7.80499 20.146C7.8567 20.0142 7.78799 19.987 7.67146 19.9641C6.12934 19.6589 4.6793 19.1247 3.30435 18.4075C2.69444 18.0894 2.28642 17.6272 2.17875 16.9665C1.98111 15.7529 2.94733 14.5835 4.18769 14.4387C4.67222 14.3823 5.12061 14.4806 5.54103 14.7C6.65282 15.2808 7.82801 15.6948 9.08573 15.894C9.55325 15.9681 10.0243 16.0052 10.4986 16.0209C11.1768 16.0431 11.8533 16.0617 12.5284 15.9681C13.173 15.8787 13.7801 15.6868 14.3606 15.4126C14.9613 15.129 15.5819 14.8836 16.162 14.5609C17.4548 13.8417 18.608 12.9662 19.5919 11.898C19.9561 11.5029 20.3708 11.1739 20.9162 11.0221C21.9186 10.7432 22.9539 11.1559 23.4735 12.0385C23.6743 12.3795 23.7951 12.741 23.7855 13.1355V13.1352Z"
              fill="white"
            />
            <path
              d="M11.3272 13.3271C13.9619 13.3271 16.0977 11.3248 16.0977 8.85494C16.0977 6.38505 13.9619 4.38281 11.3272 4.38281C8.69248 4.38281 6.55664 6.38505 6.55664 8.85494C6.55664 11.3248 8.69248 13.3271 11.3272 13.3271Z"
              fill="#E4244B"
            />
            <path
              d="M46.5713 6.61307C48.0829 8.09061 48.8391 10.1505 48.8391 12.7932C48.8391 15.4358 48.0829 17.4901 46.5713 18.9553C45.0589 20.4209 42.9448 21.1534 40.2289 21.1534H37.4995V29.6215H32.7715V4.39709H40.2289C42.9448 4.39709 45.0589 5.13586 46.5713 6.6134V6.61307ZM43.9956 12.7932C43.9956 10.0068 42.7273 8.61323 40.1903 8.61323H37.4995V16.9734H40.1903C42.7273 16.9734 43.9956 15.5806 43.9956 12.7935V12.7932Z"
              fill="white"
            />
            <path
              d="M60.7169 25.0451H54.4127L53.3364 29.6215H48.416L55.0276 4.39709H60.1017L66.7133 29.6215H61.7929L60.7165 25.0451H60.7169ZM59.756 20.829L58.6796 16.1444C58.4742 15.1838 58.1158 13.1176 57.6032 9.94633H57.4878C57.1286 12.5651 56.7826 14.631 56.45 16.1444L55.3736 20.829H59.7556H59.756Z"
              fill="white"
            />
            <path
              d="M69.4043 4.39709H83.8958V8.54118H74.1323V14.1625H77.1305C79.7954 14.1625 81.8394 14.8594 83.2618 16.2526C84.6839 17.6461 85.3951 19.5201 85.3951 21.8742C85.3951 27.0396 82.6402 29.6218 77.1305 29.6218H69.4043V4.39709ZM76.7848 25.4054C77.9632 25.4054 78.8798 25.1172 79.5333 24.5404C80.1868 23.964 80.5137 23.0755 80.5137 21.8739C80.5137 20.6723 80.1868 19.808 79.5333 19.2073C78.8798 18.607 77.9632 18.3065 76.7848 18.3065H74.1327V25.4054H76.7848Z"
              fill="white"
            />
            <path
              d="M90.488 27.7837C88.9887 26.2703 88.2393 24.1802 88.2393 21.5136V12.505C88.2393 9.83842 88.9887 7.74828 90.488 6.23488C91.9873 4.72148 94.0688 3.96478 96.7344 3.96478C99.4 3.96478 101.443 4.72148 102.942 6.23488C104.441 7.74828 105.191 9.83842 105.191 12.505V21.5136C105.191 24.1802 104.441 26.2703 102.942 27.7837C101.443 29.2971 99.3735 30.0538 96.7344 30.0538C94.0954 30.0538 91.9873 29.2971 90.488 27.7837ZM99.406 24.6666C100.008 23.9341 100.309 22.8829 100.309 21.5136V12.505C100.309 11.1121 100.008 10.0546 99.406 9.33373C98.8036 8.61289 97.9128 8.25263 96.7344 8.25263C95.556 8.25263 94.6585 8.6192 94.0437 9.35166C93.4284 10.0848 93.121 11.1353 93.121 12.5046V21.5133C93.121 22.8826 93.4284 23.9338 94.0437 24.6663C94.6585 25.3994 95.5553 25.7653 96.7344 25.7653C97.9135 25.7653 98.8036 25.3994 99.406 24.6663V24.6666Z"
              fill="white"
            />
            <path
              d="M123.065 8.61289H117.645V29.6215H112.802V8.61289H107.382V4.39676H123.065V8.61289H123.065Z"
              fill="white"
            />
            <path
              d="M141.093 4.39709L134.712 23.8561C134.148 25.5136 133.526 26.781 132.847 27.6579C132.168 28.5351 131.342 29.1477 130.368 29.4957C129.394 29.8436 128.125 30.0183 126.563 30.0183H125.563V26.0545H126.716C127.767 26.0545 128.555 25.8148 129.08 25.3337C129.605 24.8535 130.086 23.9165 130.522 22.523L123.795 4.39743H128.792L132.636 15.6765L136.134 4.39743H141.093V4.39709Z"
              fill="white"
            />
            <path
              d="M158.044 8.61289H152.625V29.6215H147.781V8.61289H142.361V4.39676H158.045V8.61289H158.044Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_6434_4247">
              <rect width="158" height="34" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
