import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetError,
  useWatch,
  UseFormClearErrors,
} from "react-hook-form";
import moment, { Moment } from "moment";

import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";
import { Filters } from "components/UI/Filters";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { useAppSelector } from "services/store/store";
import { department } from "services/store/modules/directory";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { selectUsersByRoles } from "services/store/modules/user/selectors";
import { FormInput } from "components/UI/Form/FormInput";
import { UserRole } from "graphql/types/types";

import "./styles.scss";

export type FiltersState = {
  dateFrom: Moment;
  dateTo: Moment;
  departamentId?: number | null;
  managerId?: number | null;
  projectName?: string;
};

type Props = {
  open: boolean;
  isDirty: boolean;
  control: Control<FiltersState>;
  errors: FieldErrors<FiltersState>;
  className?: string;
  onCancel: () => void;
  omSubmit: () => void;
  onReset: () => void;
  setError: UseFormSetError<FiltersState>;
  clearErrors: UseFormClearErrors<FiltersState>;
};

const MAX_DAY_PERIOD = 90;

const getDisabledDateFrom = (start: Moment, end: Moment) => {
  return (
    end.diff(start, "days") > MAX_DAY_PERIOD || start.diff(end, "days") >= 0
  );
};

const getDisabledDateTo = (start: Moment, end: Moment) => {
  return moment().isSameOrBefore(end) || start.diff(end, "days") >= 0;
};

export const FiltersModal: React.FC<Props> = ({
  open,
  isDirty,
  control,
  errors,
  onCancel,
  omSubmit,
  onReset,
  setError,
  clearErrors,
}) => {
  const dateFrom = useWatch({ control, name: "dateFrom" });
  const dateTo = useWatch({ control, name: "dateTo" });

  const departmentList = useAppSelector(department);
  const managerList = useAppSelector((state) =>
    selectUsersByRoles(state, [UserRole.Manager])
  );

  const handleClick = () => {
    omSubmit();
    onCancel();
  };

  return (
    <Modal
      className="filtersModal"
      open={open}
      size="small"
      onCancel={onCancel}
      footer={null}
    >
      <Filters className="filtersModal-filters" />

      <Controller
        name="dateFrom"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormDatePicker
            label="Период от"
            format="DD.MM.YYYY"
            picker="date"
            error={error?.message}
            disabledDate={(date) => getDisabledDateFrom(date, dateTo)}
            value={value}
            onChange={(value) => {
              clearErrors("dateFrom");

              if (value === null) {
                setError("dateFrom", {
                  message: "Период должен быть заполнен",
                });
              }

              onChange(value);
            }}
          />
        )}
      />

      <Controller
        name="dateTo"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormDatePicker
            label="Период до"
            format="DD.MM.YYYY"
            picker="date"
            error={error?.message}
            disabledDate={(date) => getDisabledDateTo(dateFrom, date)}
            value={value}
            onChange={(value) => {
              clearErrors("dateTo");

              if (value === null) {
                setError("dateTo", { message: "Период должен быть заполнен" });
              }

              onChange(value);
            }}
          />
        )}
      />

      <Controller
        name="managerId"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Менеджер вакансии"
            placeholder="Выберите менеджера"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            options={managerList?.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="departamentId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <FormSelect
            label={"Департамент"}
            placeholder="Выберите департамент"
            options={departmentList.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="projectName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"Проект"}
            placeholder="Выберите проект"
            {...field}
          />
        )}
      />

      <Button
        className="filtersModal-btn"
        loading={false}
        disabled={Boolean(Object.values(errors).length)}
        onClick={handleClick}
      >
        Применить
      </Button>
      <Button
        className="filtersModal-btn"
        variant="primary"
        disabled={!isDirty}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onReset();
        }}
      >
        Очистить все фильтры
      </Button>
    </Modal>
  );
};
