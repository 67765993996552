import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";
import { DefaultRootState } from "react-redux";
import { VacancyType } from "graphql/types/types";
import { transformAndSortCategories } from "common/helpers/transformAndSortCategories";
import { selectVacancyData } from "../vacancy/selectors";

const selectVacanciesList = (state: RootState) => state.vacanciesList;

export const selectDailyPlanList = (state: RootState) =>
  state.vacanciesList.dailyPlanList;
export const selectDailyPlanListLoading = (state: RootState) =>
  state.vacanciesList.dailyPlanList.loading;
export const selectVacanciesDictionaries = (state: RootState) =>
  state.vacanciesList.dictionaries;
export const selectVacanciesListByCategory = (state: RootState) =>
  state.vacanciesList.vacanciesListByCategory;
export const selectActiveCategory = (state: RootState) =>
  state.vacanciesList.activeCategory;

export const selectActiveVacancy = createSelector(
  selectVacanciesList,
  (state) => state.activeVacancy.id
);

export const selectDisabledVacancyList = createSelector(
  selectVacanciesList,
  (state) => state.disabledVacancyList.list
);

export const selectVacancyDisabledManagerIdsById = createSelector(
  selectDisabledVacancyList,
  selectVacancyData,
  (_: DefaultRootState, vacancyId: VacancyType["id"]) => vacancyId,
  (list, vacancy, vacancyId) => {
    const { managersIds, ownerId } = list[vacancyId] || {};
    return (
      vacancy?.managersIds || [
        ...(managersIds || []),
        ...(ownerId ? [Number(ownerId)] : []),
      ]
    );
  }
);

export const selectActiveVacancyById = createSelector(
  selectVacanciesListByCategory,
  selectActiveVacancy,
  ({ data }, vacancyId) =>
    data.data?.find((vacancy) => vacancy.id === vacancyId) ?? null
);

export const selectPositionCategories = createSelector(
  selectVacanciesDictionaries,
  (state) => state.data.positionCategories ?? []
);
export const selectDailyPlanListVacancy = createSelector(
  selectDailyPlanList,
  selectPositionCategories,
  (dailyPlanList, positionCategories) => {
    const emptyPlanList = transformAndSortCategories(positionCategories);
    return dailyPlanList.data.length === 0 ? emptyPlanList : dailyPlanList.data;
  }
);

export const selectCategoryById = createSelector(
  selectVacanciesDictionaries,
  selectActiveCategory,
  (items, { activeCategory }) =>
    items.data.positionCategories?.find((item) => item.id === activeCategory) ??
    null
);
