import React, { useState } from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { ToastContainer } from "react-toastify";
import { Breadcrumbs } from "components";
import { Sidebar } from "components/redezign/Sidebar";
import "./layout.css";
import {
  authInfo,
  authInfoThunk,
  isMenuOpen,
  toSetMenu,
} from "services/store/modules/auth";
import {
  currentHotCallDataSelector,
  isActiveCallSelector,
} from "services/store/modules/sockets/selectors";
import { ModalCallResult } from "components/Modals/ModalCallResult";
import {
  currentCallReply,
  isModalResult,
  sendResultCall,
  setCurrentCallClaim,
  setCurrentCallReply,
  setModalResult,
} from "services/store/modules/calls";
import { IUpdateCallPayload } from "services/store/modules/calls/types";
import NotificationChat from "components/UI/Notifications/NotificationChat";
import { useAppDispatch } from "services/store/store";
import { useAppSelector } from "services/store/store";
import { SidePanel } from "components/SidePanel";
import { useSockets } from "common/hooks/sockets/useSockets";
import { useOktell } from "common/hooks/sockets/useOktell";
import { Header } from "components/Header";
import { useFetchUsers } from "common/hooks/users/useFetchUsers";

export const MainLayout: React.FC = (props) => {
  const dispatch = useAppDispatch();

  useSockets();
  useOktell();
  const { fetchUsers } = useFetchUsers();

  const authData = useAppSelector(authInfo);
  const isMenuOpened = useAppSelector(isMenuOpen);
  const isActiveCall = useAppSelector(isActiveCallSelector);
  const isModalCallResult = useAppSelector(isModalResult);
  const currentReplyCall = useAppSelector(currentCallReply);
  const currentHotCallData = useAppSelector(currentHotCallDataSelector);

  const [isSideBarVisible, setSideBarVisible] = useState(false);

  const closeModal = () => {
    dispatch(setModalResult(false));
    dispatch(setCurrentCallReply(undefined));
    dispatch(setCurrentCallClaim(undefined));
  };

  const onSubmit = (e: {
    result: string;
    status?: string;
    notThroughReason?: string;
    sourceId?: number | string;
  }) => {
    const payload = {
      form: {
        result: e?.result || "",
        status: currentHotCallData?.status,
        notThroughReason: e?.notThroughReason || "",
      },
      id: currentHotCallData?.id || "1",
      sourceStatus: e?.status,
      sourceId: e?.sourceId,
    };

    dispatch(sendResultCall(payload as IUpdateCallPayload));
  };

  const computedTypeModal = () => {
    return !!currentReplyCall?.id;
  };

  const computedModalTitle = () => {
    return isActiveCall ? "Звонок" : "Результат звонка";
  };

  React.useEffect(() => {
    Promise.all([dispatch(authInfoThunk()), fetchUsers()]);
  }, []);

  return (
    <Layout className="layout layout--main">
      <Sidebar
        isCollapsed={!isMenuOpened}
        onCollapse={() => dispatch(toSetMenu(!isMenuOpened))}
      />
      <Layout
        className={`layout__wrapper ${
          !isMenuOpened ? "is-collapsed" : "is-expanded"
        }`}
      >
        {authData && (
          <Header
            authData={authData}
            setVisible={() => setSideBarVisible(true)}
          />
        )}

        <Content className="layout__content">
          <Breadcrumbs />
          {props.children}
        </Content>

        {isModalCallResult ? (
          <ModalCallResult
            visible={isModalCallResult}
            title={computedModalTitle()}
            onCancel={closeModal}
            onSubmit={onSubmit}
            isReply={computedTypeModal()}
            closable={false}
          />
        ) : null}
        <ToastContainer />
      </Layout>
      <NotificationChat />
      {authData && (
        <SidePanel
          isVisible={isSideBarVisible}
          user={authData}
          onClose={() => setSideBarVisible(false)}
        />
      )}
    </Layout>
  );
};

export default MainLayout;
