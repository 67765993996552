import React from "react";
import classNames from "classnames/dedupe";

import "./styles.scss";
import { Loader } from "../icons";

interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  variant?: Variant;
  icon?: React.ReactNode;
  className?: string;
  block?: boolean;
  size?: "small" | "normal";
  loading?: boolean;
  rightIcon?: React.ReactNode;
  href?: string;
}

export type Variant = "default" | "primary" | "link" | "green";

const rootClassName = "ui-button";

const loaderColorByVariant: Record<Variant, string> = {
  default: "#fff",
  primary: "#0057AC",
  green: "#1EB22D",
  link: "#0057AC",
};

export const Button: React.FC<Props> = ({
  variant = "default",
  size = "normal",
  className,
  children,
  block,
  icon,
  disabled,
  loading,
  rightIcon,
  href,
  ...props
}) => {
  const buttonClassNames = classNames(className, rootClassName, {
    [`${rootClassName}--${variant}`]: true,
    [`${rootClassName}--empty`]: !Boolean(children),
    [`${rootClassName}--block`]: block,
    [`${rootClassName}--disabled`]: disabled,
    [`${rootClassName}--loading`]: loading,
    [`${rootClassName}--${size}`]: size,
  });
  const iconClassNames = classNames("ui-button-icon", {
    "ui-button-icon--with-text": Boolean(children),
  });

  if (variant === "link") {
    return (
      <a
        className={classNames(className, rootClassName, {
          [`${rootClassName}--link`]: true,
        })}
        href={href}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <button className={buttonClassNames} {...props}>
      {icon && !loading && <span className={iconClassNames}>{icon}</span>}
      {loading && (
        <span className="ui-button-icon ui-button-icon--spinner">
          <Loader color={loaderColorByVariant[variant]} />
        </span>
      )}
      {children && <span className="ui-button-text">{children}</span>}
      {rightIcon && <span className="ui-button-icon-right">{rightIcon}</span>}
    </button>
  );
};
