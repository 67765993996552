import { UserRole } from "graphql/types/types";

const ADMIN = [UserRole.Admin];
const HRBP = [...ADMIN, UserRole.Hrbp];
const MODERATOR = [...ADMIN, UserRole.Moderator];
const EMPLOYEE_CC = [...ADMIN, UserRole.EmployeeCc];
const OWNER = [...ADMIN, UserRole.Owner];
const MANAGER = [...ADMIN, UserRole.Manager];

export const isAdmin = (userRole: UserRole) => ADMIN.includes(userRole);
export const isOwner = (userRole: UserRole) => OWNER.includes(userRole);
export const isHrbp = (userRole: UserRole) => HRBP.includes(userRole);
export const isModerator = (userRole: UserRole) => MODERATOR.includes(userRole);
export const isManager = (userRole: UserRole) => MANAGER.includes(userRole);
export const isEmployeeCc = (userRole: UserRole) =>
  EMPLOYEE_CC.includes(userRole);

export const hasUpdateAccess = (userRole: UserRole) =>
  isModerator(userRole) || isEmployeeCc(userRole);

export const hasAcceptAccess = (userRole: UserRole) =>
  isModerator(userRole) || isEmployeeCc(userRole) || isHrbp(userRole);

export const hasModerationAccess = (userRole: UserRole) =>
  isHrbp(userRole) || isModerator(userRole);

export const hasEditAccess = (userRole: UserRole) =>
  isOwner(userRole) || isHrbp(userRole) || isModerator(userRole);
