import { Action } from "redux";
import { Channel } from "./types";
import { addVacancy, removeVacancy } from "../vacanciesList";
import { addReply, removeReplay } from "../repliesList";
import { setVacancyManagersIds } from "../vacancy";

type ActionsByChannelPayload = {
  vacancyId?: number;
  replyId?: number;
  managersIds: number[];
  owner: number;
};

type ActionMap = {
  addAction: Action<
    "disabledVacancyList/addVacancy" | "disabledReplies/addReply"
  > | null;
  removeAction: Action<
    "disabledVacancyList/removeVacancy" | "disabledReplies/removeReplay"
  > | null;
  setManagersIds?: Action<"vacancy/setVacancyManagersIds"> | null;
};

type Result = Record<Channel, ActionMap>;

export const getActionsByChannel = ({
  vacancyId,
  replyId,
  managersIds,
  owner,
}: ActionsByChannelPayload): Result => ({
  [Channel.EnableVacancy]: {
    addAction: vacancyId
      ? addVacancy({ vacancyId, ownerId: Number(owner), managersIds })
      : null,
    removeAction: vacancyId ? removeVacancy({ vacancyId }) : null,
    setManagersIds: setVacancyManagersIds({ managersIds: null }),
  },
  [Channel.EnableReply]: {
    addAction: replyId
      ? addReply({ replyId, ownerId: Number(owner), managersIds })
      : null,
    removeAction: replyId ? removeReplay({ replyId }) : null,
  },
});
